import axios from "axios";

const api_endpoint = process.env.REACT_APP_BACKEND_URL;
export const productFetchAPI = async (type, category, series) => {
  try {
    console.log(type, category, series)
    const result = await axios.get(`${api_endpoint}product-list/get/products`, {
      params: {
        type: type,
        category: category,
        series: series,
      },
    });
    return result; // Move this line inside the try block
  } catch (err) {
    return err;
  }
};
export const ProductDescFetchAPI = async (prod_id) => {
  try {
    const result = axios.get(`${api_endpoint}product-list/get/product`, {
      params: {
        id: prod_id,
      },
    });
    return result;
  } catch (err) {
    return err;
  }
};
export const AddToCartAPI = async (data) => {
  try {
    const result = axios.post(`${api_endpoint}cart/post/to/cart`, data);
    return result;
  } catch (err) {
    return err;
  }
};
export const AddDetails = async (data) => {
  try {
    const result = axios.post(`${api_endpoint}user/post/user/details`, data);
    return result;
  } catch (error) {
    return error;
  }
};
export const GetCartedItems = async (dataArr) => {
  try {
    const result = await axios.post(`${api_endpoint}cart/get/cart/products`, dataArr);
    return result.data.data; // Return the data property of the Axios response
  } catch (error) {
    return error;
  }
};
export const GetProductDetails = async (id) => {
  try {
    const result = await axios.get(`${api_endpoint}cart/get/product/details`, { params: { id } });
    return result;
  } catch (error) {
    return error;
  }
};
export const getSeries = async () => {
  try {
    const result = await axios.get(`${api_endpoint}search/get/search`)
    return result;
  } catch (error) {
    return error;
  }
}
export const CheckCartedItem = async (data) => {
  try {
    const result = await axios.post(`${api_endpoint}cart/check/cart`, data)
    return result;
  } catch (error) {
    return error;
  }
}
export const GetEventCoupons = async () => {
  try {
    const result = await axios.get(`${api_endpoint}coupon/get/coupons`);
    return result.data;
  } catch (error) {
    return error;
  }
}
export const getSeasonalProducts = async () => {
  try {
    const result = await axios.get(`${api_endpoint}product-list/get/seasonal-products`);
    return result.data;
  } catch (error) {
    return error;
  }
}
export const getBestSellers = async () => {
  try {
    const result = await axios.get(`${api_endpoint}product-list/get/best-sellers`);
    return result.data;
  } catch (error) {
    return error;
  }
}
export const getNewestProducts = async () => {
  try {
    const result = await axios.get(`${api_endpoint}product-list/get/newest-products`);
    return result.data;
  } catch (error) {
    return error;
  }
}
export const getMostDiscountedProducts = async () => {
  try {
    const result = await axios.get(`${api_endpoint}product-list/get/top-discounted-products`);
    return result.data;
  } catch (error) {
    return error;
  }
}
export const getSuggestion = async (answers) => {
  try {
    const result = await axios.post(`${api_endpoint}suggestion/analyze`, answers);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export const getCODMail = async (body) => {
  try {
    const result = axios.post(`${api_endpoint}coupon/send/email`, body);
    return result;
  } catch (error) {
    throw error;
  }
}
export const getOrderedItems = async (body) => {
  try {
    const result = axios.post(`${api_endpoint}order-list/get-order-list`, { "orderListIds": body });
    return result;
  } catch (error) {
    throw error;
  }
}
export const postExchangeProduct = async (body) => {
  try {
    const result = axios.post(`${api_endpoint}order-list/exchange/product`, body);
    return result;
  } catch (error) {
    throw error;
  }
}
export const postCancelProduct = async (body) => {
  try {
    const result = axios.post(`${api_endpoint}order-list/cancel/product`, body);
    return result;
  } catch (error) {
    throw error;
  }
}
export const registerPartner = async (body) => {
  try {
    const result = axios.post(`${api_endpoint}affiliate/affiliate/sign/up`, body);
    return result;
  } catch (error) {
    throw error;
  }
}

export const getPartnerID = async (body) => {
  try {
    const result = axios.post(`${api_endpoint}affiliate/get/affiliate/details`, body)
    return result;
  } catch (error) {
    throw error;
  }
}

export const getSuggestionProducts = async (body) => {
  try {
    console.log("Body for API call is: ", body);

    const result = await axios.post(`${api_endpoint}suggest/products/api/suggest-products`, body);
    return result;
  } catch (error) {
    console.error("Error making API call:", error);
    throw error;
  }
};

export const handleOnlinePayment = async (amount, id, name, email, contact, address, method) => {
  let orderData;
  if (!method.includes('card')) {
    // const intAmount = amount.parseInt(amount);
    const updatedAmount = amount - (amount * (2 / 100));
    const updatedIntAmount = parseInt(updatedAmount);
    orderData = {
      amount: updatedIntAmount, // Amount in INR
      currency: 'INR',
      receipt: id,
    };
  } else {
    orderData = {
      amount: amount, // Amount in INR
      currency: 'INR',
      receipt: id,
    };
  }

  try {
    // Step 1: Create an order on your backend
    const { data: order } = await axios.post(`${api_endpoint}order-list/rzpay/online`, orderData);

    return new Promise((resolve, reject) => {
      // Step 2: Set up the Razorpay options
      const options = {
        key: process.env.RZPAY_ID, // Replace with your Razorpay key_id
        amount: order.amount, // Amount in paisa
        currency: order.currency,
        name: 'JAWD Lifestyles LLP',
        description: 'Transaction for online payment for product purchase',
        image: 'https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/jawd_logo_white.png?updatedAt=1724558092677?tr-f_auto,q_auto', // Optional
        order_id: order.id, // Order ID from Razorpay response
        handler: async (response) => {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          // Step 4: Verify the payment on your backend
          try {
            const { data } = await axios.post(`${api_endpoint}order-list/rzpay/verify`, paymentData);
            if (data.status === 'success') {
              resolve('Payment successful');
            } else {
              reject('Payment verification failed');
            }
          } catch (error) {
            reject('Payment verification failed');
          }
        },
        prefill: {
          name: name, // Pre-fill the user's name
          email: email, // Pre-fill the user's email
          contact: contact, // Pre-fill the user's contact
          method: method
        },
        notes: {
          address: address, // Custom note, for example, shipping address
        },
        theme: {
          color: '#2c2c2c', // Customize the color of the checkout form
          hide_topbar: true
        }
      };

      // Step 3: Open Razorpay Checkout
      const razorpay = new window.Razorpay(options);

      razorpay.on('payment.failed', (response) => {
        console.error('Payment failed:', response.error.description);
        reject('Payment failed. Please try again.');
      });

      razorpay.open();
    });
  } catch (error) {
    console.error('Payment initiation failed:', error);
    throw new Error('Payment initiation failed. Please try again.');
  }
};
export const fetchPinCodeDetails = async (pinCode) => {
  try {
    const response = await axios.get(`${api_endpoint}product-list/api/pincode/`, {
      params: {
        filter_codes: pinCode,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching pin code details:', error);
    return null;
  }
};

export const checkCustomCoupon = async (couponName) => {
  try {
    const response = await axios.post(`${api_endpoint}coupon/check/coupon`,{couponName: couponName});
    return response;
  } catch (error) {
    return error;
  }
}